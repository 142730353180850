export const ID = "id";
export const TRANSKEY = "TransKey"
export const TRANSLATIONKEY = "Translation Key";
export const FALSE = false;
export const TRUE = true;
export const DELETEMESSAGE = "Do you really want to delete this translation key :";
export const DELETEHEADER = "Delete translation";
export const OK = "OK";
export const CANCEL = "CANCEL";
export const ITEMSPERPAGE = "items per page :";
export const ROWSPERPAGE = [25, 50, 100, 500];
export const NORECORDS = "No Records Available";
export const SUCCESS = 'success';
export const ERROR = 'error';
export const WARNING = 'warning';
export const INFO = 'info';
export const GENERICMESSAGE = "Error occurred please try again";
export const DEFAULTLANGUAGE = "ENGLISH";
export const LANGUAGES = "Languages";
export const ADDAPPLICATIONEHEADER = "New translation page";
export const DEFAULTPAGENUMBER = 1;
export const DEFAULTSEARCHQUERY = "";
export const EDITTRANSLATIONHEADER = "Edit translation";
export const ADDTRANSLATION = "Add Translation";
export const ADDTRANSLATIONPLACEHOLDER = "Add translation";
export const DUPLICATEKEY = "value already taken";
export const DUPLICATEMESSAGE = "Translation key/value already exists";
export const SAVETRANSLATIONMESSAGE = " has been saved successfully";
export const KEYPROGRESS = "Translation key is in progress";
export const ENTER = "Enter";
export const DEFAULTLANG = { LangOrder: 1, Name: "ENGLISH", id: "en" };
export const DEFAULTLANGID = "en"
export const APPLICATIONPROGRESS = "Add Application is in progress";
export const APPLICATIONSUCCESS = "Application is successfully added.";
export const APPLICATIONFAILURE = "Error occured,please try again after some time";
export const APPLICATIONVALIDATE = "Application Name already exist";
export const DELETEMESSAGESUCCESS = " deleted successfully."
export const EDITAPPHEADER = "Edit page";
export const EDITAPPLICATIONPROGRESS = "Edit Application is in progress";
export const APPLICATIONEDITSUCCESS = "Application is successfully edited.";
export const DELETEHEAPPHEADER = "Delete translation page";
export const DELETEAPPMESSAGE = "Do you really want to delete this translation page :";
export const DELETEAPPMESSAGECONTINUE = "All translations will be lost.";
export const DELETEAPPLICATIONPROGRESS = "Delete Application is in progress";
export const DELETEVALIDATE = "Application Name doesn't exist";
export const DELETEFAILURE = "Error occured,please try again after some time";
export const ADDNEWAPPLICATION = "Create new translation page";
export const SVGPATH = "path";
export const SPAN = "span";
export const UPDATETRANSLATIONMESSAGE = " has been updated successfully";
export const LANGENGLISH = "en";
export const ADDAUTOTRANSLATION = "Auto Translate";
export const EDITAPPLABEL = "Page name";
export const CURRENT = "Current";
export const TRANSLATION = "Translation";
export const NEW = "New";
export const DEFAULTERRORMESSAGE = "Unable to fetch data,please try again after some time";
export const VALIDATEMESSAGE = "Please edit the required field.";
export const SVGCLICK = "svg";
export const UPLOADBULKHEADER = "Import File";
export const UPLOADBULKFILEVALIDATION = "json/xls/csv file formats are accepted only.";
export const UPLOADBULKNOFILEVALIDATION = "Please upload json file";
export const UPLOADBULKFILEFORMAT = "JSON";
export const IMPORTMENU = "Import translation file";
export const BULKIMPORTPROGRESS = "Bulk upload in progress";
export const BULKIMPORTPSUCCESS = "Bulk upload Successful";
export const EXPORTSUCCESS = "Exported successfully";
export const NOTRANSLATIONSAVAILABLE = "No translations available to export";
export const NONAMEVALUE = "no name value";
export const NONAMEMESSAGE = "Please provide translation details";
export const WRONGID = "Wrong id";
export const DEFAULTROWNUMBER = 25;
export const SEARCH = "Search";
export const CLICK = "click";
export const TRANSLATIONPROGRESS = "Add Translation is in progress";
export const EDITTRANSLATIONPROGRESS = "Edit Translation is in progress";
export const DELETETRANSLATIONPROGRESS = "Delete Translation is in progress";
export const FileUploadValidation = "Please select a language and json file in order to validate";
export const SAMETEXT = "New text is same as the current text";
export const APPLICATIONPERMISSION = "You do not have permissions to access this application. You can check the applications you have access to on the Carrier Apps Hub";
export const OPENCAH = "Open Carrier Apps Hub";
export const EXPORTALL = 'Export All';
export const EXPORTAUTOTRANSLATION = 'Export Auto Translations Only';
export const IMPORTEXPORT = 'Import/Export';
export const ADDALLTRANSLATIONURI = 'addAllTranslations';
export const ADDBULKTRANSLATIONURI = 'addBulkTranslations';
